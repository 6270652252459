import React, { useContext } from "react";
import Layout from "layout";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/body/itch/aquaphor-itch-ointment-front.webp";
import backImage from "images/body/itch/aquaphor-itch-ointment-back.webp";
import frontZoomImage from "images/body/itch/aquaphor-itch-ointment-front-zoom.webp";
import backZoomImage from "images/body/itch/aquaphor-itch-ointment-back-zoom.webp";

import img4 from "images/callouts/cout-footer-eczema.webp";
import img5 from "images/callouts/cout-footer-itch-calming-lotion.webp";
import img6 from "images/body/itch/section-cout-aquaphor-itchrelief.webp";

import imgChart1 from "images/body/itch/chart-aquaphor-flare-reduction.webp";
import imgChart2 from "images/body/itch/chart-aquaphor-itch-reduction.webp";

import "./aquaphoritchreliefointment.scss";

function IndexPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-body-itch page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Itch Relief Ointment"
        pageDescription="Learn about Aquaphor Itch Relief Ointment"
      />
      <div className="gradient-bg gray-grad">
        <div className="inner-body-content gradient-bg">
          <div className="top-content-level-3">
            <section className="inner-centered-container">
              <ProductDetails
                categoryBgColor="#8399BA"
                categoryName="ITCH"
                categoryFontColor="#ffffff"
                productName="Aquaphor<sup>®</sup> Itch Relief Ointment"
                productSlider={[frontImage, backImage]}
                zoomImages={[frontZoomImage, backZoomImage]}
              >
                <div className="functions">
                  <p className="font-semi-bold lightblue-text sub-title">
                    Soothes and relieves itch for up to 12 hours<sup>1</sup>
                  </p>
                  <p>
                    <span className="font-semi-bold">
                      Aquaphor Itch Relief Ointment
                    </span>{" "}
                    is formulated with 1% hydrocortisone (external analgesic) to
                    provide immediate and long-lasting itch relief from eczema
                    flares and skin irritations.
                  </p>
                  <ul className="weldon-bullets">
                    <li>
                      <span>Maximum strength relief</span>
                    </li>
                    <li>
                      <span className="font-semi-bold">
                        Clinically proven results
                      </span>
                    </li>
                    <li>
                      <span>
                        Gentle and safe for sensitive skin, including pediatric
                        patients over <nobr>2 years</nobr> of age
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="key-ingredients">
                  <p className="font-semi-bold lightblue-text sub-title">
                    Active ingredient
                  </p>
                  <p className="font-semi-bold">
                    1% hydrocortisone (external analgesic)
                  </p>
                  <ul className="weldon-bullets">
                    <li>
                      <span>Soothes and relieves itching</span>
                    </li>
                  </ul>
                </div>
                <div className="key-ingredients no-brd">
                  <p className="font-semi-bold lightblue-text sub-title">
                    Key ingredients
                  </p>
                  <div className="key-group">
                    <Row>
                      <Col sm={12} md={6} order={{ xs: 1, md: 1 }}>
                        <p className="font-semi-bold">Menthoxypropanediol</p>
                        <ul className="weldon-bullets">
                          <li>
                            <span>Soothes skin</span>
                          </li>
                        </ul>
                      </Col>
                      <Col sm={12} md={6} order={{ xs: 4, md: 2 }}>
                        <p className="font-semi-bold">Petrolatum</p>
                        <ul className="weldon-bullets">
                          <li>
                            <span>Locks in moisture</span>
                          </li>
                        </ul>
                      </Col>

                      <Col sm={12} md={6} order={{ xs: 2, md: 3 }}>
                        <p className="font-semi-bold">Panthenol</p>
                        <ul className="weldon-bullets">
                          <li>
                            <span>Softens and conditions skin</span>
                          </li>
                          <li>
                            <span>Binds water to support moisture</span>
                          </li>
                        </ul>
                      </Col>
                      <Col sm={12} md={6} order={{ xs: 5, md: 4 }}>
                        <p className="font-semi-bold">Glycerin</p>
                        <ul className="weldon-bullets">
                          <li>
                            <span>Binds water</span>
                          </li>
                          <li>
                            <span>Replenishes moisture reservoir</span>
                          </li>
                        </ul>
                      </Col>

                      <Col sm={12} md={6} order={{ xs: 3, md: 5 }}>
                        <p className="font-semi-bold">Lanolin alcohol</p>
                        <ul className="weldon-bullets">
                          <li>
                            <span>Supports barrier repair</span>
                          </li>
                          <li>
                            <span>Defends against moisture loss</span>
                          </li>
                        </ul>
                      </Col>
                      <Col sm={12} md={6} order={{ xs: 6, md: 6 }}>
                        <p className="font-semi-bold">Bisabolol</p>
                        <ul className="weldon-bullets">
                          <li>
                            <span>Soothes dry, irritated skin</span>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              </ProductDetails>
              <Row>
                <Col xs={12}>
                  <div className="features">
                    <p className="font-semi-bold lightblue-text sub-title">
                      Formulated for long-lasting itch relief
                    </p>
                    <ul className="checkmarks lightblue">
                      <li>
                        <span>Fragrance-free </span>
                      </li>
                      <li>
                        <span>paraben-free</span>
                      </li>
                      <li>
                        <span>hypoallergenic</span>
                      </li>
                    </ul>
                  </div>
                  <p className="references last">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
              </Row>
            </section>
          </div>
          <div className="gradient-bg">
            <section className="inner-centered-container">
              <AccordionContent
                accordionHeading="FLARE REDUCTION"
                productThemeColor="itch-lightblue"
                id="flarereduction"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart1}
                      className="accordion-chart"
                      alt="Chart showing reduced symptoms of eczema flares"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span> Pediatric
                      subjects were assessed prior to application of Aquaphor
                      Itch Relief Ointment, then applied the test material to
                      the assigned areas twice daily for 7 days, and reassessed
                      at Day 7 (n=32).
                    </p>
                    <p className="references-symbols double last">
                      **Statistically significant compared to baseline (
                      <span className="font-italic">p</span>&lt;0.001).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      <p className="spacer">
                        53.6% reduction in erythema at <nobr>Day 7</nobr> vs
                        baseline
                        <sup>1</sup>**
                      </p>
                      <p className="spacer">
                        69.5% reduction in pruritus at <nobr>Day 7</nobr> vs
                        baseline
                        <sup>1</sup>**
                      </p>
                      <p className="spacer">
                        57.6% reduction in excoriation at <nobr>Day 7</nobr> vs
                        baseline
                        <sup>1</sup>**
                      </p>
                    </div>
                  </Col>
                </Row>
              </AccordionContent>

              <AccordionContent
                accordionHeading="ITCH REDUCTION"
                productThemeColor="itch-lightblue"
                id="itchreduction"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart2}
                      className="accordion-chart"
                      alt="Chart showing reduced severity of itch"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span> Seven-day
                      study of adult subjects with dry and itchy skin to
                      determine the tolerability and efficacy of Aquaphor Itch
                      Relief Ointment to alleviate itch due to atopic dermatitis
                      (n=30).
                    </p>
                    <p className="references-symbols double last">
                      **Statistically significant compared to Day 1 baseline (
                      <span className="font-italic">p</span>&lt;0.001).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      57.6% reduction from <nobr>Day 1</nobr> to{" "}
                      <nobr>
                        Day 7<sup>1</sup>**
                      </nobr>
                    </div>
                  </Col>
                </Row>
              </AccordionContent>
            </section>
          </div>
          <section className="callouts-container gradient-bg section-support-resource light-purple">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                  <TypeC
                    buttonText="DOWNLOAD HERE"
                    buttonUrl={getUrlFromSlug(
                      "itch-relief-ointment-long-lasting-relief",
                      state?.sanityData?.allResources
                    )}
                    className="center-v"
                    isExternalLink={true}
                    targetOpen="_blank"
                  >
                    <p className="reg-text-big">
                      Support your recommendation with this resource
                    </p>
                    <p className="reg-text">
                      Review with patients how a formulation with 1%
                      hydrocortisone can provide immediate and long-lasting itch
                      relief.
                    </p>
                  </TypeC>
                </Col>
                <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                  <img
                    src={img6}
                    alt="Formulation with 1% hydrocortisone for Itch Relief"
                    className="cout-product-img"
                  />
                </Col>
              </Row>
            </div>
          </section>

          <section className="callouts-container gradient-bg blue-grad">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img4}
                    imgAlt="Eczema Regimen"
                    caption="Choose a proactive therapeutic<br>
                    regimen for eczema"
                    buttonText="Eczema Regimen"
                    buttonUrl="/body/eczema"
                    imgStyles={{ height: 158 }}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img5}
                    imgAlt="Eucerin<sup>®</sup> Itch Relief "
                    caption="Choose a menthol-enriched<br>
                    formula for a calming effect
                    "
                    buttonText="Eucerin<sup>®</sup> Itch Relief "
                    buttonUrl="/body/itch/eucerinitchrelieflotion"
                    imgStyles={{ height: 165 }}
                  />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
